import {Chip, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip} from "@mui/material";
import {Article, AutoGraph, Science} from "@mui/icons-material";
import {annotationTypes, annotationTypeSets, predictionThresholds} from "../../config";
import {groupBy} from "../../utils/grouping";
import {MaterialEntity, MaterialEntityAnnotation} from "../../api";
import {uniqueAnnotationsLabels} from "../../utils/material_entities";
import {ChipEvidenceOrPrediction} from "../../sections/@dashboard/foods/FoodInsightsSection";

export default function IngredientHighlights(props: {
    ingredient: MaterialEntity,
    size?: "medium" | "small",
    align?: "inherit" | "center" | "left" | "right" | "justify"
}) {

    const insights = props.ingredient.annotations ? props.ingredient.annotations.filter((annotation: MaterialEntityAnnotation) => annotationTypeSets.insights.has(annotation.type)) : [];
    const insightsGroupedByType = groupBy(insights, "type")
    console.log(insightsGroupedByType)
    const rows = Object.entries<any>(insightsGroupedByType).map(([label, annotations]) => {
        return {
            title: annotationTypes[label],
            insightsValues: uniqueAnnotationsLabels(annotations).map((insight: MaterialEntityAnnotation) => ({
                key: insight.id,
                label: insight.label,
                value: insight.value
            }))
        }
    })

    return (
        <TableContainer sx={{pt: 2}}>
            <Table aria-label="simple table" size={props.size}>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.title}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" align={props.align} scope="row" >
                                {row.title}
                            </TableCell>
                            <TableCell component="th" align={props.align} scope="row">
                                <Stack direction={"row"} spacing={1}>
                                    {row.insightsValues.map((insight: any) => <ChipEvidenceOrPrediction
                                        insight={insight}
                                        key={insight.key}/>)}

                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
