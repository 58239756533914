import {SetStateAction, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {apiRoutes} from "../../config";
import Box from "@mui/material/Box";
import {Button, Container, Grid, InputAdornment, List, ListItem, ListItemText, Stack, Typography} from "@mui/material";
import {StyledSearch} from "../search";
import {Search} from "@mui/icons-material";
import {SortIngredientCardList} from "../../sections/@dashboard/ingredients";
import Pagination from "@mui/material/Pagination";
import Modal from "@mui/material/Modal";
import openAPIGeneratorMaterialLibraryInstance from "../../openAPIGeneratorMaterialLibraryInstance";
import {MaterialEntity} from "../../api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 475,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function IngredientsModal(props: { state: any, setState: any, open: any, handleClose: any }) {

    function updateIngredients(ingredient: MaterialEntity) {

        props.setState(
            {
                ...props.state,
                ingredients: [...props.state.ingredients, ingredient]
            }
        )
        props.handleClose();
    }

    const [queryKey, setQueryKey] = useState('');

    const handleQueryByKey = (event: { target: { value: SetStateAction<string>; }; }) => {
        setPage(1);
        setQueryKey(event.target.value);
    };

    const sortInitialState = {
        property: 'none',
        order: 'desc',
        label: 'None'
    }
    const [sort, setSort] = useState(sortInitialState);

    const [page, setPage] = useState(1)

    const handlePageChange = (event: any, page: SetStateAction<number>) => {
        setPage(page);
    }

    const pageSize = 10

    const sortOptions = [
        {property: 'none', order: "asc", label: 'None'},
        {property: 'date', order: "desc", label: 'Newest'},
        {property: 'date', order: "asc", label: 'Oldest'},
        {property: 'name', order: "desc", label: 'Desc: Name'},
        {property: 'name', order: "asc", label: 'Asc: Name'},
    ];

    const {data: ingredientsData, isSuccess} = useQuery(
        {
            queryKey: [apiRoutes.materialLibrary.baseEndpoint, page, queryKey, sort],
            queryFn: () => {
                return openAPIGeneratorMaterialLibraryInstance
                .materialLibraryCompoundsList(sort.property, page, pageSize, queryKey)
                    .then(response => response.data)
            },
            initialData: {
                "count": 0,
                "next": null,
                "previous": null,
                "results": []
            }
        }
    )

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6"
                            component="h2">
                    Add New Ingredient
                </Typography>
                <Typography id="modal-modal-description" sx={{mt: 4}}>
                    <Box>
                        <Stack direction="row"
                               alignItems="center"
                               justifyContent="space-between"
                               spacing={2}
                               mb={8}
                        >
                            <Stack direction={"row"} spacing={2}>
                                <StyledSearch
                                    fontSize={16}
                                    //disabled
                                    width={150}
                                    value={queryKey}
                                    onChange={handleQueryByKey}
                                    placeholder="Search..."
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Search/>
                                        </InputAdornment>
                                    }
                                />
                                <SortIngredientCardList value={sort}
                                                        setValue={setSort}
                                                        sortOptions={sortOptions}/>
                            </Stack>
                        </Stack>
                        <Grid container spacing={2}>
                            {isSuccess ?
                                <Container>
                                    <List>
                                        {ingredientsData.results.map((ingredient: MaterialEntity) => (
                                            <ListItem key={ingredient.id}>
                                                <ListItemText
                                                    primary={ingredient.name}/>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => updateIngredients(ingredient)}
                                                >
                                                    Add
                                                </Button>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Container> : <></>
                            }
                        </Grid>
                    </Box>
                </Typography>
                <Grid item xs={12}>
                    <Stack spacing={2} alignItems={"center"}>
                        <Pagination
                            variant="outlined"
                            shape="rounded"
                            count={ingredientsData ? Math.ceil(ingredientsData.count / pageSize): 0}
                            page={page}
                            onChange={handlePageChange}
                        />
                    </Stack>
                </Grid>
            </Box>
        </Modal>
    )
}