import {SetStateAction, useEffect, useRef, useState} from "react";
// @mui
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";
import {CheckCircle, OpenInNewOutlined,} from "@mui/icons-material";
import {TabContext, TabPanel} from "@mui/lab";
// utils
import {fDate} from "../../../utils/formatTime";
// components
import {ChipStack} from "../../../components/chip-stacks";
// mock
// @ts-ignore
import SmilesDrawerFunc from "../../../utils/smilesDrawer";
import {groupBy} from "../../../utils/grouping";
import {useQuery} from "@tanstack/react-query";
import {apiRoutes, imageFallBacks} from "../../../config";
import openAPIGeneratorMaterialLibraryInstance from "../../../openAPIGeneratorMaterialLibraryInstance";
import {ChemicalEntity, MaterialEntity, MaterialEntityAnnotation} from "../../../api";

// ----------------------------------------------------------------------
function LinkChip(props: {entity: any, externalLink: any}) {
    return (
        <Chip
            icon={<OpenInNewOutlined/>}
            color={'primary'}
            variant={'outlined'}
            component={'a'}
            clickable
            label={`${props.entity} ${props.externalLink.uid}`}
            href={props.externalLink.url !== "nan" ? props.externalLink.url : null}
            target={"_blank"}
            rel={"noopener noreferrer"}
        />
    )
}

// ----------------------------------------------------------------------
function Summary(props: {ingredient: any}) {

    const svgElement = useRef(null);

    const SETTINGS = {
        width: 200,
        height: 200,
    };

    useEffect(() => {
        SmilesDrawerFunc(SETTINGS, svgElement, props.ingredient.canonical_smiles)
    }, [SETTINGS, props.ingredient])

    const rows = [
        {
            name: 'Name',
            value: props.ingredient.name
        },
        {
            name: 'Alternative names',
            value: props.ingredient.alternative_name ? props.ingredient.alternative_name : "No alternative names found"
        },
        {
            name: 'Description',
            value: props.ingredient.description ? props.ingredient.description : ""
        },
        {
            name: 'Date',
            value: fDate(props.ingredient.date_created)
        }
    ]

    return (
        <Box>
            <Typography variant={"h6"} paragraph>
                Summary
            </Typography>
            {
                props.ingredient.type === "COM" ?
                    <svg ref={svgElement} width={200}/>
                    :
                    <img
                        src={props.ingredient.img ? `${props.ingredient.img}` : imageFallBacks.ingredient}
                        alt={props.ingredient.name} width={200}/>
            }
            <TableContainer>
                <Table aria-label="simple table">
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.value}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

// ----------------------------------------------------------------------
function Biochemistry(props: {ingredient: ChemicalEntity}) {

    const rows = [
        {
            name: 'Molecular formula',
            value: props.ingredient.molecular_formula ? props.ingredient.molecular_formula : ""
        },
        {
            name: 'Molecular weight',
            value: props.ingredient.molecular_weight ? `${props.ingredient.molecular_weight} g/mol` : ""
        },
        {
            name: 'IUPAC name',
            value: props.ingredient.iupac_name ? props.ingredient.iupac_name : ""
        },
        {
            name: 'InChI',
            value: props.ingredient.inchi ? props.ingredient.inchi : ""
        },
        {
            name: 'InChI key',
            value: props.ingredient.inchi_key ? props.ingredient.inchi_key : ""
        },
        {
            name: 'Canonical SMILES',
            value: props.ingredient.canonical_smiles ? props.ingredient.canonical_smiles : ""
        },
        {
            name: 'Isomeric SMILES',
            value: props.ingredient.isomeric_smiles ? props.ingredient.isomeric_smiles : ""
        }
    ]

    return (
        <Box>
            <Typography variant={"h6"} paragraph>
                Biochemistry
            </Typography>
            <TableContainer sx={{pt: 2}}>
                <Table aria-label="simple table">
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.value}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

// ----------------------------------------------------------------------
function Identifiers(props: {ingredient: MaterialEntity}) {

    const rows = props.ingredient.annotations ? props.ingredient.annotations.filter((annotation: { type: string; }) => annotation.type === "EXT").map(
        (annotation: MaterialEntityAnnotation) => ({
            "name": annotation.label,
            "value": {
                "uid": annotation.value,
                "url": annotation.url
            }
        })
    ) : []

    return (
        <Box>
            <Typography variant={"h6"} paragraph>
                Identifiers
            </Typography>
            <TableContainer sx={{pt: 2}}>
                <Table aria-label="simple table">
                    <TableBody>
                        {rows.map((row: any) => (
                            <TableRow
                                key={row.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <LinkChip entity={row.name} externalLink={row.value}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

// ----------------------------------------------------------------------
function Source(props: {ingredient: any}) {

    return (
        <Box>
            <Typography variant={"h6"} paragraph>
                Identifiers
            </Typography>
            <TableContainer sx={{pt: 2}}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow
                            key={'1'}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                Date
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {fDate(props.ingredient.date_created)}
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key={'1'}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                Origin
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <ChipStack stringArray={props.ingredient.properties.sources}/>
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key={'1'}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                Geography
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {props.ingredient.properties.geography.length === 0 ?
                                    <Typography sx={{paddingLeft: 1}} variant="body2" color="text.secondary">
                                        No location found
                                    </Typography>
                                    :
                                    <ChipStack stringArray={props.ingredient.properties.geography}/>
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key={'1'}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                Min. Price
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {props.ingredient.properties.cost} €
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key={'1'}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                Suppliers
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <Stack direction={"row"}
                                       alignItems={"center"}
                                       justifyContent={"center"}
                                       spacing={2}>
                                    <ChipStack stringArray={['CD Formulation', 'Sigma-Aldrich']}/>
                                    <Button variant={"outlined"} sx={{ml: 2}}>
                                        See all suppliers
                                    </Button>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

// ----------------------------------------------------------------------
function Regulatory(props: {ingredient: MaterialEntity}) {
    const regulatoryAgencies = ['EPA', 'FDA', 'EMA', 'GRAS']

    const regulatoryAnnotations = props.ingredient.annotations ? props.ingredient.annotations.filter((annotation: { type: string; }) => annotation.type === "REG") : []
    const labelAnnotations = groupBy(regulatoryAnnotations, "label")


    return (
        <Box>
            <Typography variant={"h6"} paragraph>
                Regulatory
            </Typography>
            <List>
                {Object.entries<any>(labelAnnotations).map(([label, annotations]) => {
                    return (
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircle color={"success"}/>
                            </ListItemIcon>
                            <ListItemText>
                                <Stack direction={"column"}
                                       alignItems={"flex-start"}
                                       justifyContent={"flex-start"}
                                       spacing={1}>
                                    <Typography variant={"body1"}>
                                        {label}
                                    </Typography>
                                    {annotations.map((annotation: { source: any; value: any; url: any; }) =>
                                        <LinkChip entity={annotation.source} externalLink={{
                                            uid: annotation.value,
                                            url: annotation.url
                                        }}/>
                                    )}
                                    {/*<Typography variant={"body2"} color={"text.secondary"}>*/}
                                    {/*    {annotations.descripton}*/}
                                    {/*</Typography>*/}
                                </Stack>
                            </ListItemText>
                        </ListItem>
                        // )
                        // } else {
                        //     return (
                        //         <ListItem>
                        //             <ListItemIcon>
                        //                 <DoNotDisturb color={"error"}/>
                        //             </ListItemIcon>
                        //             <ListItemText>
                        //                 <Stack direction={"column"}
                        //                        alignItems={"flex-start"}
                        //                        justifyContent={"flex-start"}
                        //                        spacing={1}>
                        //                     <Typography variant={"body1"}>
                        //                         {agency}
                        //                     </Typography>
                        //                     <LinkChip entity={agency} externalLink={{
                        //                         uid: 'GRN000000000',
                        //                         url: 'https://www.fda.gov/food/food-additives-petitions/food-additive-petitions-ingredients-added-food'
                        //                     }}/>
                        //                     <Typography variant={"body2"} color={"text.secondary"}>
                        //                         {faker.lorem.paragraph()}
                        //                     </Typography>
                        //                 </Stack>
                        //             </ListItemText>
                        //         </ListItem>
                    )
                })}
                {/*// })}*/}
            </List>
        </Box>
    )
}

// ----------------------------------------------------------------------
function Literature(props: {ingredient: MaterialEntity}) {

    const {isLoading, data: ingredientLiteratureData} = useQuery(
        {
            queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, props.ingredient.id, "literature"],
            queryFn: () => openAPIGeneratorMaterialLibraryInstance
                .materialLibraryMaterialEntitiesLiteratureRetrieve(props.ingredient.id)
                .then(
                    response => response.data
                ),
        }
    )
    const rows: any = ingredientLiteratureData ? ingredientLiteratureData : []

    return (
        <Box>
            <Typography variant={"h6"} paragraph>
                Literature
            </Typography>
            {isLoading ?
                <CircularProgress/>
                :
                <Stack direction={"column"}
                       alignItems={"flex-start"}
                       justifyContent={"flex-start"}
                       spacing={2}>
                    {rows.map((row: any) => (
                        // TODO: Refactor Card into an individual component
                        <Card sx={{minWidth: 700, maxWidth: 700}}>
                            <CardContent>
                                <Stack direction={"column"} spacing={2}>
                                    <Typography variant={"h6"} color={"primary"}>
                                        {row.title}
                                    </Typography>
                                    {/*<Typography variant={"body2"} color={"text.secondary"}>*/}
                                    {/*    {fDate(row.date_created)}*/}
                                    {/*</Typography>*/}
                                    {
                                        row.summary ?
                                            <Typography variant="body2" color="text.secondary">
                                                {row.summary.length > 250 ? row.summary.substring(0, 280) + "..." : row.summary}
                                            </Typography>
                                            : <></>
                                    }
                                </Stack>
                            </CardContent>
                            <CardActions sx={{pl: 2, pb: 2}}>
                                <LinkChip entity={'PubMed'}
                                          externalLink={{
                                              uid: row.id.split(":")[1],
                                              url: "https://pubmed.ncbi.nlm.nih.gov/" + row.id.split(":")[1]
                                          }}/>
                                {row.xdbRefs.filter((x: string) => x.substring(0, 3) === 'doi')[0] ?
                                    <LinkChip entity={'DOI'}
                                              externalLink={{
                                                  uid: row.xdbRefs.filter((x: string) => x.substring(0, 3) === 'doi')[0].split(":")[1],
                                                  url: `https://doi.org/${row.xdbRefs.filter((x: string) => x.substring(0, 3) === 'doi')[0].split(":")[1]}`
                                              }}/> : <></>
                                }
                            </CardActions>
                        </Card>
                    ))}
                </Stack>
            }
        </Box>
    )
}

// ----------------------------------------------------------------------
function Patents(props: { ingredient: any }) {
    const rows = props.ingredient.annotations ? props.ingredient.annotations.filter((annotation: {
        source_type: string;
        type: string;
    }) => annotation.source_type === "DOC" && annotation.type === "PAT") : []

    return (
        <Box>
            <Typography variant={"h6"} paragraph>
                Patents
            </Typography>
            <Stack direction={"column"}
                   alignItems={"flex-start"}
                   justifyContent={"flex-start"}
                   spacing={2}>
                {rows.map((row: any ) => (
                    <Card sx={{minWidth: 700, maxWidth: 700}}>
                        <CardContent>
                            <Stack direction={"column"} spacing={2}>
                                <Typography variant={"h6"} color={"primary"}>
                                    {row.source}
                                </Typography>
                                <Typography variant={"body2"} color={"text.secondary"}>
                                    {fDate(row.date_created)}
                                </Typography>
                            </Stack>
                        </CardContent>
                        <CardActions sx={{pl: 2, pb: 2}}>
                            <LinkChip entity={'PubMed Patents'}
                                      externalLink={{
                                          uid: row.id,
                                          url: row.url
                                      }}/>
                        </CardActions>
                    </Card>
                ))}
            </Stack>
        </Box>
    )
}

export default function IngredientDetails(props: {ingredient: MaterialEntity}) {
    // handle tabs
    const [value, setValue] = useState('1');

    const handleChange = (event: any, value: SetStateAction<string>) => {
        setValue(value);
    }

    return (
        <Box sx={{flexGrow: 1, display: 'flex'}}>
            <TabContext value={value}>
                {props.ingredient.type === "COM" ?

                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{mr: 2, minWidth: 100}}>

                        <Tab label="Summary" value={'1'}/>
                        <Tab label="Biochemistry" value={'2'}/>
                        <Tab label="Identifiers" value={'3'}/>
                        {/*<Tab label="Source" value={'4'}/>*/}
                        <Tab label="Regulatory" value={'5'}/>
                        <Tab label="Literature" value={'6'}/>
                        {/*<Tab label="Patents" value={'7'}/>*/}
                    </Tabs> : props.ingredient.type === "CPX" ?
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{mr: 2, minWidth: 100}}>

                            <Tab label="Summary" value={'1'}/>
                            {/*<Tab label="Nutritional" value={'2'}/>*/}
                            <Tab label="Identifiers" value={'3'}/>
                            {/*<Tab label="Source" value={'4'}/>*/}
                            {/*<Tab label="Regulatory" value={'5'}/>*/}
                            <Tab label="Literature" value={'6'}/>
                            {/*<Tab label="Patents" value={'7'}/> :*/}
                        </Tabs> :
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{mr: 2, minWidth: 100}}>

                            <Tab label="Summary" value={'1'}/>
                        </Tabs>
                }
                <TabPanel value={"1"}>
                    <Summary ingredient={props.ingredient}/>
                </TabPanel>
                <TabPanel value={"2"}>
                    <Biochemistry ingredient={props.ingredient}/>
                </TabPanel>
                <TabPanel value={"3"}>
                    <Identifiers ingredient={props.ingredient}/>
                </TabPanel>
                <TabPanel value={"4"}>
                    <Source ingredient={props.ingredient}/>
                </TabPanel>
                <TabPanel value={"5"}>
                    <Regulatory ingredient={props.ingredient}/>
                </TabPanel>
                <TabPanel value={"6"}>
                    <Literature ingredient={props.ingredient}/>
                </TabPanel>
                <TabPanel value={"7"}>
                    <Patents ingredient={props.ingredient}/>
                </TabPanel>
            </TabContext>
        </Box>
    )
}