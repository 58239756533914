import {Helmet} from 'react-helmet-async';
// hooks
import React, {ChangeEvent, useState} from 'react';
// @mui
import {Box, Grid, InputAdornment, Stack} from '@mui/material';
import {Search} from "@mui/icons-material";
// components and sections
import {FilterIngredientCardList, SortIngredientCardList} from "../sections/@dashboard/ingredients";
import {IngredientCard} from "../components/ingredient-card";
import {StyledSearch} from "../components/search";
import {QueryClient, useQuery} from "@tanstack/react-query";
import {apiRoutes} from "../config";
import Pagination from "@mui/material/Pagination";
import {SortOption, SortOptionCreateParams} from "../utils/filteringAndSorting";
import openAPIGeneratorMaterialLibraryInstance from "../openAPIGeneratorMaterialLibraryInstance";
import {useSearchParams} from "react-router-dom";
import {MaterialEntity} from "../api";

// ----------------------------------------------------------------------

const sortInitialState: SortOption = {
    property: 'none',
    order: 'desc',
    label: 'None'
}

export const ingredientsPageQuery = (searchQuery?: string,
                                     sort?: SortOption,
                                     page?: number,
                                     pageSize?: number) => ({
        queryKey: [apiRoutes.materialLibrary.compounds.baseEndpoint, page, searchQuery, sort],
        queryFn: () => {
            return openAPIGeneratorMaterialLibraryInstance
                .materialLibraryCompoundsList(sort ? SortOptionCreateParams(sort) : undefined, page, pageSize, searchQuery)
                .then(response => response.data)
        },
        initialData: {
            "count": 0,
            "next": null,
            "previous": null,
            "results": []
        }
    }
)


export const ingredientsPageLoader = async (queryClient: QueryClient) => {
    const query = ingredientsPageQuery('', sortInitialState, 1, 9)
    console.log(query.queryKey)
    return queryClient.ensureQueryData(query)
}

export default function IngredientsPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams.get("search") ?? undefined);

    const [filters, setFilters] = useState([]);


    const handleQueryByKey = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPage(1);
        setSearchQuery(event.target.value);
        setSearchParams(event.target.value ? {"search": event.target.value} : undefined)
    };

    const [sort, setSort] = useState(sortInitialState);

    const [page, setPage] = useState(1)

    const sortOptions: SortOption[] = [
        {property: 'none', order: "asc", label: 'None'},
        {property: 'date', order: "desc", label: 'Newest'},
        {property: 'date', order: "asc", label: 'Oldest'},
        {property: 'name', order: "desc", label: 'Desc: Name'},
        {property: 'name', order: "asc", label: 'Asc: Name'},
    ];

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number): void => {
        setPage(page);
    }

    const pageSize = 9

    const {data: ingredientsData,} = useQuery(
        ingredientsPageQuery(searchQuery, sort, page, pageSize)
    )


    return (
        <>
            <Helmet>
                <title> Ingredients | CibusAI </title>
            </Helmet>

            <Box>
                <Stack direction="row"
                       alignItems="center"
                       justifyContent="space-between"
                       spacing={2}
                       mb={5}>
                    <Stack direction={"row"} spacing={3}>
                        <StyledSearch
                            fontSize={16}
                            width={300}
                            value={searchQuery}
                            onChange={handleQueryByKey}
                            placeholder="Search Ingredient..."
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search/>
                                </InputAdornment>
                            }
                        />
                        <FilterIngredientCardList value={filters} setValue={setFilters}/>
                        <SortIngredientCardList value={sort} setValue={setSort} sortOptions={sortOptions}/>
                    </Stack>
                </Stack>
                <Grid container spacing={3}>
                    {ingredientsData.results.map((ingredient: MaterialEntity) => {
                        return (
                            <Grid item xs={12} sm={6} lg={4}>
                                <IngredientCard ingredient={ingredient}/>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={2} alignItems={"center"}>
                        <Pagination
                            variant="outlined"
                            shape="rounded"
                            count={Math.ceil(ingredientsData.count / pageSize)}
                            page={page}
                            onChange={handlePageChange}
                        />
                    </Stack>
                </Grid>
            </Box>
        </>
    );
}
