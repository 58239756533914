import {useEffect, useRef, useState} from "react";
// router
import {Link as RouterLink} from "react-router-dom";
// @mui
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Collapse,
    Divider,
    IconButton,
    Link,
    Stack,
    Typography
} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import {
    AssuredWorkload,
    AttachMoney,
    BookmarkAdd,
    ExpandMore,
    LocationOn,
    Store,
    TipsAndUpdatesOutlined
} from "@mui/icons-material";
// components
import SmilesDrawerFunc from "../../utils/smilesDrawer";
import {ChipStack} from "../chip-stacks";
import {IngredientInsightsGrid} from "../ingredients-insights-grid";
import {getLogisticsColumns} from "../../sections/@dashboard/foods/FoodDetails";
import {imageFallBacks} from "../../config";
import {ChemicalEntity, MaterialEntity} from "../../api";
// ----------------------------------------------------------------------
const ExpandMoreButton: any = styled((props: any) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

// ----------------------------------------------------------------------
export default function IngredientCard(props: {ingredient: any}) {

    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const theme = useTheme();

    const svgElement: any = useRef(null);

    const SETTINGS: any = {
        // width: 200,
        // height: 200,
    };

    useEffect(() => {
        SmilesDrawerFunc(SETTINGS, svgElement, props.ingredient.canonical_smiles)
    }, [SETTINGS, props.ingredient])

    const data: any = getLogisticsColumns(props.ingredient)

    return (
        <Card sx={{maxWidth: 345}}>
            <CardContent>
                <Stack direction={'row'} spacing={1}>
                    <Box>
                        <Link sx={{marginRight: 2}}
                              variant="h5"
                              underline="hover"
                              noWrap
                              component={RouterLink}
                              to={`/ingredients/${props.ingredient.id}`}>
                            {/*{ingredient.name}*/}
                            {props.ingredient.name.length > 15 ? props.ingredient.name.substring(0, 15) + "..." : props.ingredient.name}
                        </Link>
                        <Typography variant="body2" color="text.secondary">
                            {props.ingredient.description ? props.ingredient.description.length > 70 ? props.ingredient.description.substring(0, 70) + "..." : props.ingredient.description : ""}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: "100px",
                        }}
                    >
                        {
                            props.ingredient.type === "COM" ?
                                <></>
                                :
                                <img
                                    src={props.ingredient.img ? `${props.ingredient.img}` : imageFallBacks.ingredient}
                                    alt={props.ingredient.name} width={200}/>
                        }
                    </Box>
                </Stack>
                <Divider sx={{my: 2}}/>
                <Stack direction={'row'} spacing={1}>
                    <Typography variant="body1" color={theme.palette.primary.main} paragraph>
                        Insights
                    </Typography>
                    <TipsAndUpdatesOutlined color={"primary"}/>
                </Stack>
                <IngredientInsightsGrid ingredient={props.ingredient}/>
            </CardContent>
            <CardActions disableSpacing
                         sx={{px: 2}}>
                <IconButton disabled aria-label="add to favorites">
                    <BookmarkAdd/>
                </IconButton>
                <ExpandMoreButton
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMore/>
                </ExpandMoreButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent sx={{pt: 2}}>
                    <Stack direction={'column'} spacing={1}>
                        <Typography variant="body1" paragraph>
                            Details
                        </Typography>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                        >
                            <Store/>
                            {data.Source ?
                                <ChipStack stringArray={data.Source.map((obj: { label: any; }) => obj.label)}/> :
                                <Typography sx={{paddingLeft: 1}} variant="body2" color="text.secondary">
                                    Missing Source info
                                </Typography>
                            }
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                        >
                            <AssuredWorkload/>
                            {data.Regulatory ?
                                <ChipStack stringArray={data.Regulatory.map((obj: { label: any; }) => obj.label)}/> :
                                <Typography sx={{paddingLeft: 1}} variant="body2" color="text.secondary">
                                    Missing regulatory info
                                </Typography>
                            }
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                        >
                            <LocationOn/>
                            {data.Geography ?
                                <ChipStack stringArray={data.Geography.map((obj: { label: any; }) => obj.label)}/> :
                                <Typography sx={{paddingLeft: 1}} variant="body2" color="text.secondary">
                                    No location found
                                </Typography>
                            }
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                        >
                            <AttachMoney/>
                            {data.Cost ?
                                <Typography sx={{paddingLeft: 1}} variant="body2" color="text.secondary">
                                    {data.Cost}
                                </Typography> :
                                <Typography sx={{paddingLeft: 1}} variant="body2" color="text.secondary">
                                    No pricing found
                                </Typography>
                            }
                        </Stack>
                    </Stack>
                </CardContent>
            </Collapse>
        </Card>
    )
}